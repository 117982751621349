import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Icon, Input, Menu, Modal, Table, Typography } from "antd";

import moment from "moment";

import { roleAndPermissionService } from "./../../redux/services";

import DefaultTemplate from "./../../templates/defaultTemplate";

import ModalCreate from "./create";
import ModalEdit from "./edit";
import ModalShow from "./show";
import ModalExport from "./export";

const config = {
	title           : "Papéis e permissões",
	orderByField    : "created_at",
	orderBySort     : "desc",
	permissionPrefix: "roles",
};

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading         : false,
			data              : [],
			pagination        : {
				current : 1,
				pageSize: 20,
				total   : 0,
			},
			orderByField      : config.orderByField,
			orderBySort       : config.orderBySort,
			search            : "",
			// Actions
			createModalVisible: false,
			editModalVisible  : false,
			showModalVisible  : false,
			exportModalVisible: false,
		};
	}

	componentDidMount() {
		// Fecth all
		this.fetchGetAll();
	}

	menuMain = () => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".create") && <Menu.Item key="store">
				<a onClick={this.createOpen}>
					<Icon type="plus" />Cadastrar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".export") && <Menu.Item key="export">
				<a onClick={this.exportOpen}>
					<Icon type="export" />Exportar
				</a>
			</Menu.Item>}
		</Menu>
	);

	menu = (item) => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".show") && <Menu.Item key="show">
				<a onClick={() => this.showOpen(item)}>
					<Icon type="file-text" />Visualizar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".edit") && <Menu.Item key="edit">
				<a onClick={() => this.editOpen(item)}>
					<Icon type="edit" />Editar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".delete") && <Menu.Item key="delete">
				<a onClick={() => this.deleteConfirm(item)}>
					<Icon type="delete" />Excluir
				</a>
			</Menu.Item>}
		</Menu>
	);

	columns = () => {
		let columns = [
			{
				title    : "ID",
				key      : "id",
				dataIndex: "id",
				width    : 90,
				sorter   : true,
				fixed    : "left",
			},
			{
				title    : "Nome",
				key      : "name",
				dataIndex: "name",
				width    : 260,
				sorter   : true,
			},
			{
				title    : "Descrição",
				key      : "description",
				dataIndex: "description",
				width    : 390,
				sorter   : true,
			},
			{
				title    : "Permissões",
				key      : "permissions_count",
				dataIndex: "permissions_count",
				width    : 135,
				sorter   : true,
			},
			{
				title    : "Usuários",
				key      : "users_count",
				dataIndex: "users_count",
				width    : 110,
				sorter   : true,
			},
			{
				title           : "Criação",
				key             : "created_at",
				dataIndex       : "created_at",
				width           : 150,
				sorter          : true,
				defaultSortOrder: "descend",
				render          : (text) => moment(text).format("DD/MM/YYYY HH:mm"),
			},
			{
				title    : "Últ. modificação",
				key      : "updated_at",
				dataIndex: "updated_at",
				width    : 160,
				sorter   : true,
				fixed    : window.innerWidth > 991 ? "right" : false,
				render   : (text) => moment(text).format("DD/MM/YYYY HH:mm"),
			},
		];

		if( this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete") )
		{
			columns.push({
				title    : "Ações",
				key      : "actions",
				width    : 78,
				className: "actions disable-sort",
				fixed    : window.innerWidth > 767 ? "right" : false,
				render   : (text, item) => (
					<Dropdown overlay={this.menu(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
						<Button icon="more" />
					</Dropdown>
				),
			});
		}

		return columns;
	};

	fetchGetAll = () => {
		const {pagination, orderByField, orderBySort, search} = this.state;

		this.setState({
			isLoading: true,
		});

		roleAndPermissionService.getAll({
			page   : pagination.current,
			limit  : pagination.pageSize,
			orderBy: `${orderByField}:${orderBySort}`,
			search : search,
		})
		.then((response) => {
			this.setState(state => ({
				isLoading : false,
				data      : response.data.data,
				pagination: {
					...state.pagination,
					total: response.data.meta.total,
				},
			}));
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	};

	handleTableChange = (pagination, filters, sorter) => {
		this.setState(state => ({
			pagination  : {
				...state.pagination,
				current : pagination.current,
				pageSize: pagination.pageSize,
			},
			orderByField: sorter.field ? sorter.field : config.orderByField,
			orderBySort : sorter.order === "descend" ? "desc" : "asc",
		}), () => {
			this.fetchGetAll();
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.fetchGetAll();
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e["type"] )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.fetchGetAll();
				}
			});
		}
	};

	/**
	 * Create
	 */
	createOpen = () => {
		this.setState({createModalVisible: true});

		// On open screen
		this.createScreen.onOpen();
	};

	createOnClose = () => {
		this.setState({createModalVisible: false});
	};

	createOnComplete = () => {
		this.setState({createModalVisible: false});

		// Fecth all
		this.fetchGetAll();
	};

	/**
	 * Edit
	 *
	 * @param id
	 * @param system
	 */
	editOpen = ({id, system}) => {
		if( system )
		{
			Modal.warning({
				title  : "Ação indisponível",
				content: "Este item é bloqueado pelo sistema e não pode ser editado.",
			});

			return false;
		}

		this.setState({editModalVisible: true});

		// On open screen
		this.editScreen.onOpen(id);
	};

	editOnClose = () => {
		this.setState({editModalVisible: false});
	};

	editOnComplete = () => {
		this.setState({editModalVisible: false});

		// Fecth all
		this.fetchGetAll();
	};

	/**
	 * Show
	 *
	 * @param id
	 */
	showOpen = ({id}) => {
		this.setState({showModalVisible: true});

		// On open screen
		this.showScreen.onOpen(id);
	};

	showOnClose = () => {
		this.setState({showModalVisible: false});
	};

	/**
	 * Delete
	 *
	 * @param id
	 * @param system
	 */
	deleteConfirm = ({id, system}) => {
		if( system )
		{
			Modal.warning({
				title  : "Ação indisponível",
				content: "Este item é bloqueado pelo sistema e não pode ser deletado.",
			});

			return false;
		}

		Modal.confirm({
			title  : "Confirmar exclusão!",
			content: "Tem certeza de que deseja excluir este registro?",
			okText : "Excluir",
			onOk   : () => {
				return this.deleteConfirmed(id);
			}
		});
	};

	deleteConfirmed = (id) => {
		return roleAndPermissionService.destroy({id})
		.then((response) => {
			// Fecth all
			this.fetchGetAll();
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
			});
		});
	};

	/**
	 * Export
	 */
	exportOpen = () => {
		this.setState({exportModalVisible: true});
	};

	exportOnClose = () => {
		this.setState({exportModalVisible: false});
	};

	exportOnComplete = () => {
		this.setState({exportModalVisible: false});
	};

	render() {
		return (
			<DefaultTemplate>
				<div className="page-content" key="1">
					<h1 className="page-title">{config.title}</h1>
					<div className="page-listing-header">
						<div className="search">
							<Input.Search
								placeholder="Pesquisar"
								onSearch={this.onSearch}
								onChange={this.onSearchChange}
								enterButton
								allowClear
							/>
						</div>
						{(this.props.permissions.includes(config.permissionPrefix + ".create") || this.props.permissions.includes(config.permissionPrefix + ".export")) && (
							<div className="actions">
								<Dropdown className="actions-dropdown" placement="bottomRight" trigger={["click"]} overlay={this.menuMain}>
									<Button type="primary" icon="more" />
								</Dropdown>
							</div>
						)}
					</div>
					{this.state.search && <Typography.Paragraph>Buscando por <Typography.Text mark>{this.state.search}</Typography.Text></Typography.Paragraph>}
					<Table
						className="page-listing-body"
						columns={this.columns()}
						rowKey="id"
						bordered
						scroll={{x: 1350}}
						dataSource={this.state.data}
						pagination={{...this.state.pagination, showSizeChanger: true, showQuickJumper: true, hideOnSinglePage: true}}
						loading={this.state.isLoading}
						onChange={this.handleTableChange}
						footer={() => `Total ${this.state.pagination.total} de registros encontrados.`}
					/>
				</div>
				<ModalCreate
					wrappedComponentRef={el => this.createScreen = el}
					visible={this.state.createModalVisible}
					onComplete={this.createOnComplete}
					onClose={this.createOnClose}
				/>
				<ModalEdit
					wrappedComponentRef={el => this.editScreen = el}
					visible={this.state.editModalVisible}
					onComplete={this.editOnComplete}
					onClose={this.editOnClose}
				/>
				<ModalShow
					ref={el => this.showScreen = el}
					visible={this.state.showModalVisible}
					onClose={this.showOnClose}
				/>
				<ModalExport
					visible={this.state.exportModalVisible}
					onComplete={this.exportOnComplete}
					onClose={this.exportOnClose}
				/>
			</DefaultTemplate>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Index);
