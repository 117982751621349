import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "antd";

import moment from "moment";

import { dashboardService } from "./../../redux/services";

import DefaultTemplate from "./../../templates/defaultTemplate";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			totals   : {},
			hasError : false,
			error    : "",
		};
	}

	componentDidMount() {
		// Fecth all
		this.fetchGetAll();
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
			hasError : false,
			error    : "",
		});

		dashboardService.getAll()
		.then((response) => {
			this.setState({
				isLoading: false,
				totals   : response.data.data.totals,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
				hasError : true,
				error    : data.error_message,
			});
		});
	};

	greeting = () => {
		const hour = moment().hour();
		let day    = "Bom dia";

		if( hour >= 19 )
		{
			day = "Boa noite";
		}
		else if( hour >= 12 )
		{
			day = "Boa tarde";
		}

		return `Olá ${this.props.user.first_name}, ${day}!`;
	};

	renderCards = () => {
		const {isLoading, totals, hasError, error} = this.state;

		if( hasError )
		{
			return (
				<div style={{textAlign: "center"}}>
					<h1>Ocorreu um erro!</h1>
					<p>{error}</p>
					<Button type="primary" size="large" onClick={this.fetchGetAll}>Tentar novamente</Button>
				</div>
			);
		}

		return (
			<div className="cards">
				<div className="groupData">
					<h2>Conteúdos</h2>
					<Row gutter={16} type="flex">
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("faq.list") ? {"data-has-link": true, onClick: () => window.location = 'faq'} : ""}>
								<h3>Ajuda</h3>
								<div className="numberTotal">{totals.faq}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("cultures.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/cultures'} : ""}>
								<h3>Culturas</h3>
								<div className="numberTotal">{totals.cultures}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("defensives.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/defensives'} : ""}>
								<h3>Defensivos</h3>
								<div className="numberTotal">{totals.defensives}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("nutritional-deficiencies.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/nutritional-deficiencies'} : ""}>
								<h3>Deficiências nutricionais</h3>
								<div className="numberTotal">{totals.nutritional_deficiencies}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("diseases.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/diseases'} : ""}>
								<h3>Doenças</h3>
								<div className="numberTotal">{totals.diseases}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("fertilizers.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/fertilizers'} : ""}>
								<h3>Fertilizantes/Corretivos</h3>
								<div className="numberTotal">{totals.fertilizers}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("leaf-fertilizers.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/leaf-fertilizers'} : ""}>
								<h3>Fertilizantes foliares</h3>
								<div className="numberTotal">{totals.leaf_fertilizers}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("news.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/news'} : ""}>
								<h3>Notícias</h3>
								<div className="numberTotal">{totals.news}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("machines-implements.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/machines-implements'} : ""}>
								<h3>Máquinas/Implementos</h3>
								<div className="numberTotal">{totals.machines_implements}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("weeds.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/weeds'} : ""}>
								<h3>Plantas daninhas</h3>
								<div className="numberTotal">{totals.weeds}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("pests.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/pests'} : ""}>
								<h3>Pragas</h3>
								<div className="numberTotal">{totals.pests}</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("varieties.list") ? {"data-has-link": true, onClick: () => window.location = 'contents/varieties'} : ""}>
								<h3>Variedades</h3>
								<div className="numberTotal">{totals.varieties}</div>
							</Card>
						</Col>
					</Row>
				</div>
				<div className="groupData">
					<h2>Conteúdos de usuários</h2>
					<Row gutter={16} type="flex">
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("customers.list") ? {"data-has-link": true, onClick: () => window.location = 'customers'} : ""}>
								<h3>Usuários</h3>
								<div className="numberTotal">{totals.customers}</div>
								<div className="numberToday"><b>{totals.customers_today}</b> hoje</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("ratings.list") ? {"data-has-link": true, onClick: () => window.location = 'ratings'} : ""}>
								<h3>Avaliações</h3>
								<div className="numberTotal">{totals.ratings}</div>
								<div className="numberToday"><b>{totals.ratings_today}</b> hoje</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("comments.list") ? {"data-has-link": true, onClick: () => window.location = 'comments'} : ""}>
								<h3>Comentários</h3>
								<div className="numberTotal">{totals.comments}</div>
								<div className="numberToday"><b>{totals.comments_today}</b> hoje</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("ask-agronomists.list") ? {"data-has-link": true, onClick: () => window.location = 'ask-agronomists/questions'} : ""}>
								<h3>Consulte os agrônomos</h3>
								<div className="numberTotal">{totals.ask_agronomists}</div>
								<div className="numberToday"><b>{totals.ask_agronomists_today}</b> hoje</div>
							</Card>
						</Col>
						<Col xs={12} sm={6} lg={4}>
							<Card loading={isLoading} {...this.props.permissions.includes("report-a-problem.list") ? {"data-has-link": true, onClick: () => window.location = 'report-a-problem'} : ""}>
								<h3>Problemas relatados</h3>
								<div className="numberTotal">{totals.report_a_problem}</div>
								<div className="numberToday"><b>{totals.report_a_problem_today}</b> hoje</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	};

	render() {
		return (
			<DefaultTemplate className="page-home">
				<div className="page-content" key="1">
					<h1 className="page-title">{this.greeting()}</h1>
					{this.renderCards()}
				</div>
			</DefaultTemplate>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		user       : state.auth.userData,
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Home);
