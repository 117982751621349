import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Icon,
  Input,
  Menu,
  Modal,
  Switch,
  Tabs,
  Table,
  Typography,
} from "antd";

import moment from "moment";

import { animalService } from "../../../redux/services";

import ModalCreate from "./create";
import ModalEdit from "./edit";
import ModalShow from "./show";

const config = {
  title: "Conteúdos",
  orderByField: "order",
  orderBySort: "asc",
  permissionPrefix: "pests",
};

class Index extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
      },
      orderByField: config.orderByField,
      orderBySort: config.orderBySort,
      search: "",
      // Actions
      createModalVisible: false,
      editModalVisible: false,
      showModalVisible: false,
      activeLoadings: [],
      // Images
      imagePreviewVisible: false,
      imagePreviewType: "image",
      imagePreviewImage: "",
    };
  }

  componentDidMount() {
    // Fecth all
    this.fetchGetAll();
  }

  menuMain = () => (
    <Menu className="actions-dropdown-menu">
      {this.props.permissions.includes(config.permissionPrefix + ".create") && (
        <Menu.Item key="store">
          <a onClick={this.createOpen}>
            <Icon type="plus" />
            Cadastrar
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  menu = (item) => (
    <Menu className="actions-dropdown-menu">
      {this.props.permissions.includes(config.permissionPrefix + ".show") && (
        <Menu.Item key="show">
          <a onClick={() => this.showOpen(item)}>
            <Icon type="file-text" />
            Visualizar
          </a>
        </Menu.Item>
      )}
      {this.props.permissions.includes(config.permissionPrefix + ".edit") && (
        <Menu.Item key="edit">
          <a onClick={() => this.editOpen(item)}>
            <Icon type="edit" />
            Editar
          </a>
        </Menu.Item>
      )}
      {this.props.permissions.includes(config.permissionPrefix + ".delete") && (
        <Menu.Item key="delete">
          <a onClick={() => this.deleteConfirm(item)}>
            <Icon type="delete" />
            Excluir
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  columns = () => {
    let columns = [
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
        width: 90,
        sorter: true,
        fixed: "left",
      },
      {
        title: "",
        key: "media",
        dataIndex: "media[0].file_sizes.admin_listing",
        width: 70,
        sorter: false,
        className: "image disable-sort",
        render: (text, item) => {
          if (!text) {
            return null;
          }

          return (
            <a onClick={() => this.onImagePreview(item.media[0])}>
              {item.media[0].type === "video" ? (
                <Icon type="video-camera" style={{ fontSize: 30 }} />
              ) : (
                <img src={text} style={{ maxHeight: "69px" }} />
              )}
            </a>
          );
        },
      },
      {
        title: "Nome",
        key: "name",
        dataIndex: "name",
        width: 350,
        sorter: true,
      },
      {
        title: "Iniciar aberto",
        key: "start_open",
        dataIndex: "start_open",
        width: 150,
        sorter: true,
        render: (text) => (text ? "Sim" : "Não"),
      },
      {
        title: "Ordem",
        key: "order",
        dataIndex: "order",
        width: 100,
        sorter: true,
        defaultSortOrder: "ascend",
      },
      {
        title: "Criação",
        key: "created_at",
        dataIndex: "created_at",
        width: 150,
        sorter: true,
        render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
      },
      {
        title: "Últ. modificação",
        key: "updated_at",
        dataIndex: "updated_at",
        width: 160,
        sorter: true,
        render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
      },
    ];

    if (this.props.permissions.includes(config.permissionPrefix + ".edit")) {
      columns.push({
        title: "Ativo",
        key: "is_active",
        dataIndex: "is_active",
        width: 78,
        sorter: true,
        className: "active",
        fixed: window.innerWidth > 767 ? "right" : false,
        render: (text, item) => (
          <Switch
            checked={item.is_active}
            loading={this.state.activeLoadings.indexOf(item.id) !== -1}
            onChange={(checked) => this.activateDeactivate(item, checked)}
          />
        ),
      });
    }

    if (
      this.props.permissions.includes(config.permissionPrefix + ".show") ||
      this.props.permissions.includes(config.permissionPrefix + ".edit") ||
      this.props.permissions.includes(config.permissionPrefix + ".delete")
    ) {
      columns.push({
        title: "Ações",
        key: "actions",
        width: 78,
        className: "actions disable-sort",
        fixed: window.innerWidth > 767 ? "right" : false,
        render: (text, item) => (
          <Dropdown
            overlay={this.menu(item)}
            className="actions-dropdown"
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button icon="more" />
          </Dropdown>
        ),
      });
    }

    return columns;
  };

  fetchGetAll = () => {
    const { pagination, orderByField, orderBySort, search } = this.state;
    const { item } = this.props;

    this.setState({
      isLoading: true,
    });

    animalService
      .getContents({
        owner_id: item.id,
        page: pagination.current,
        limit: pagination.pageSize,
        orderBy: `${orderByField}:${orderBySort}`,
        search: search,
      })
      .then((response) => {
        this.setState((state) => ({
          isLoading: false,
          data: response.data.data,
          pagination: {
            ...state.pagination,
            total: response.data.meta.total,
          },
        }));
      })
      .catch((data) => {
        console.log(data);
        this.setState({
          isLoading: false,
        });

        Modal.error({
          title: "Ocorreu um erro!",
          content: data.error_message,
        });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      (state) => ({
        pagination: {
          ...state.pagination,
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        orderByField: sorter.field ? sorter.field : config.orderByField,
        orderBySort: sorter.order === "descend" ? "desc" : "asc",
      }),
      () => {
        this.fetchGetAll();
      }
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        search: value,
      },
      () => {
        this.fetchGetAll();
      }
    );
  };

  onSearchChange = (e) => {
    // If it does not have type then it's cleaning
    if (!e["type"]) {
      const { search } = this.state;

      this.setState(
        {
          search: e.target.value,
        },
        () => {
          if (search) {
            this.fetchGetAll();
          }
        }
      );
    }
  };

  /**
   * Create
   */
  createOpen = () => {
    const { item } = this.props;

    const nextOrder =
      Math.max(...this.state.data.map((item) => item.order), 0) + 1;

    this.setState({ createModalVisible: true });

    // On open screen
    this.createScreen.onOpen(item.id, nextOrder);
  };

  createOnClose = () => {
    this.setState({ createModalVisible: false });
  };

  createOnComplete = () => {
    this.setState({ createModalVisible: false });

    // Fecth all
    this.fetchGetAll();
  };

  /**
   * Edit
   *
   * @param id
   */
  editOpen = ({ id }) => {
    const { item } = this.props;

    this.setState({ editModalVisible: true });

    // On open screen
    this.editScreen.onOpen(item.id, id);
  };

  editOnClose = () => {
    this.setState({ editModalVisible: false });
  };

  editOnComplete = () => {
    this.setState({ editModalVisible: false });

    // Fecth all
    this.fetchGetAll();
  };

  /**
   * Show
   *
   * @param id
   */
  showOpen = ({ id }) => {
    const { item } = this.props;

    this.setState({ showModalVisible: true });

    // On open screen
    this.showScreen.onOpen(item.id, id);
  };

  showOnClose = () => {
    this.setState({ showModalVisible: false });
  };

  /**
   * Delete
   *
   * @param id
   */
  deleteConfirm = ({ id }) => {
    const { item } = this.props;

    Modal.confirm({
      title: "Confirmar exclusão!",
      content: "Tem certeza de que deseja excluir este registro?",
      okText: "Excluir",
      onOk: () => {
        return this.deleteConfirmed(item.id, id);
      },
    });
  };

  deleteConfirmed = (owner_id, id) => {
    return animalService
      .destroyContent({
        owner_id,
        id,
      })
      .then((response) => {
        // Fecth all
        this.fetchGetAll();
      })
      .catch((data) => {
        console.log(data);
        Modal.error({
          title: "Ocorreu um erro!",
          content: data.error_message,
        });
      });
  };

  /**
   * Active/Desactive
   *
   * @param {number} id
   * @param {boolean} activate
   */
  activateDeactivate = ({ id }, activate) => {
    const { item } = this.props;
    const { activeLoadings } = this.state;

    if (activeLoadings.indexOf(id) === -1) {
      activeLoadings.push(id);
    }

    this.setState({
      activeLoadings: activeLoadings,
    });

    animalService
      .editContent({
        owner_id: item.id,
        id,
        is_active: activate,
      })
      .then((response) => {
        const newData = [...this.state.data];
        const index = newData.findIndex((item) => id === item.id);

        if (index !== -1) {
          const item = newData[index];

          newData.splice(index, 1, {
            ...item,
            is_active: response.data.data.is_active,
          });

          this.setState({
            data: newData,
          });
        }
      })
      .catch((data) => {
        console.log(data);
        Modal.error({
          title: "Ocorreu um erro!",
          content: data.error_message,
        });
      })
      .finally(() => {
        const { activeLoadings } = this.state;
        const index = activeLoadings.indexOf(id);

        if (index !== -1) {
          activeLoadings.splice(index, 1);

          this.setState({
            activeLoadings: activeLoadings,
          });
        }
      });
  };

  /**
   * Image preview
   */
  onImagePreviewClose = () => this.setState({ imagePreviewVisible: false });

  onImagePreview = (media) => {
    this.setState({
      imagePreviewImage: media.type === "video" ? media.video : media.file,
      imagePreviewType: media.type === "video" ? "video" : "image",
      imagePreviewVisible: true,
    });
  };

  render() {
    return (
      <div>
        <div className="page-content" key="1">
          <div className="page-listing-header">
            <div className="search">
              <Input.Search
                placeholder="Pesquisar"
                onSearch={this.onSearch}
                onChange={this.onSearchChange}
                enterButton
                allowClear
              />
            </div>
            {this.props.permissions.includes(
              config.permissionPrefix + ".create"
            ) && (
              <div className="actions">
                <Dropdown
                  className="actions-dropdown"
                  placement="bottomRight"
                  trigger={["click"]}
                  overlay={this.menuMain}
                >
                  <Button type="primary" icon="more" />
                </Dropdown>
              </div>
            )}
          </div>
          {this.state.search && (
            <Typography.Paragraph>
              Buscando por{" "}
              <Typography.Text mark>{this.state.search}</Typography.Text>
            </Typography.Paragraph>
          )}
          <Table
            className="page-listing-body"
            columns={this.columns()}
            rowKey="id"
            bordered
            scroll={{ x: 1200 }}
            dataSource={this.state.data}
            pagination={{
              ...this.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              hideOnSinglePage: true,
            }}
            loading={this.state.isLoading}
            onChange={this.handleTableChange}
            footer={() =>
              `Total ${this.state.pagination.total} de registros encontrados.`
            }
          />
        </div>
        <ModalCreate
          wrappedComponentRef={(el) => (this.createScreen = el)}
          visible={this.state.createModalVisible}
          onComplete={this.createOnComplete}
          onClose={this.createOnClose}
        />
        <ModalEdit
          wrappedComponentRef={(el) => (this.editScreen = el)}
          visible={this.state.editModalVisible}
          onComplete={this.editOnComplete}
          onClose={this.editOnClose}
        />
        <ModalShow
          ref={(el) => (this.showScreen = el)}
          visible={this.state.showModalVisible}
          onClose={this.showOnClose}
        />
        <Modal
          className="modal-image"
          visible={this.state.imagePreviewVisible}
          footer={null}
          destroyOnClose={true}
          onCancel={this.onImagePreviewClose}
        >
          {this.state.imagePreviewType === "video" ? (
            <video controls autoPlay>
              <source src={this.state.imagePreviewImage} type="video/mp4" />
            </video>
          ) : (
            <img src={this.state.imagePreviewImage} />
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.auth.userData.permissions,
  };
};

export default connect(mapStateToProps)(Index);
